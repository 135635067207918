import { createMachine } from 'xstate';

export const mainMachine = 
createMachine({
  on: {
    TRANSITION_TO_VALA: { target: 'vala'},
    TRANSITION_TO_TELKOS: { target: 'vala'},
    RESET_MACHINE: { target: 'idle'},
  },
  initial: 'idle',
  id: 'utilities-machine',
  states: {
    idle: {},
    vala: {
      id: 'vala',
      on: {
        SELECT_POSTPAID: {
          target: "vala.postpaid",
        },
        SELECT_PREPAID: {
          target: "vala.prepaid",
        },
      },
      initial: 'selectProduct',
      states: {
        selectProduct: {},
        postpaid: {
          id: 'postpaid',
          initial: "1",
          states: {
            "1": {
              id: "1",  
              on: {
                NEXT: {
                  target: "2",
                },
              },
            },
            "2": {
              on: {
                NEXT: {
                  target: "3",
                },
              },
            },
            "3": {
              on: {
                NEXT: {
                  target: "4",
                },
              },
            },
            "4": {},
          },
        },
        "prepaid": {
          id: 'prepaid'
        },
      },
    }
  },
})