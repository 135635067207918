
import React, { FC } from 'react';
import { useMachine } from '@xstate/react';
import { mainMachine } from '../../mainMachine';
import { useQuery } from 'react-query'
import { headers } from '../../common';
import {
  Button,
  Flex,
  Box,
  Link
} from 'rebass';
import {
  Label,
  Input,
} from '@rebass/forms'
import { Tabs, TabList, Tab, TabPanels, TabPanel, TabsOrientation } from '@reach/tabs';
import { Currencies, CURRENCY } from '../../common';
import Modal from 'styled-react-modal';
import axios from 'axios';
import { RedirectComponent } from '../utils/Redirect';
import { InfinitySpin } from 'react-loader-spinner';
import { BasicTable, ValaDisplayTable } from './Tables';
import { UtilityHeading } from './UtilityHeading';
import { MainOnboarding } from '../onboarding/MainOnboarding';
import { backEndBaseUrl } from "../utils/config";
import { useCheckMobileScreen } from "../../App";

type CurrencyString = 'EUR' | 'GBP' | 'USD';

type ChooseValueInputRowProps = {
  currency?: CurrencyString;
  setValue: (amount: string) => void;
  value: string;
  name?: string;
}

export const ChooseValueInputRow = ({ 
  currency = 'EUR',
  name = 'Name',
  value,
  setValue
}: ChooseValueInputRowProps) => {
  return (
    <Flex alignItems="center">
      <Label justifyContent={'flex-start'} htmlFor='name'>{name}</Label>
      <Input
        type="text"
        id='name'
        name='name'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        width="100px"
        height={'30px'}
        mx='5px'
      />
      {currency}
    </Flex>    
  )
}

type PaymentComponentProps = {
  currency?: CurrencyString,
  serviceName: string;
  referenceId?: string;
  initialValue?: string;
}

export const PaymentComponent = ({
  currency = 'EUR',
  serviceName,
  referenceId,
  initialValue = "0"
}: PaymentComponentProps) => {
  const [externalUrl, setExternalUrl] = React.useState<string | null>(null); 
  const [paymentValue, setPaymentValue] = React.useState(initialValue);
  let description = `Pagesa e faturës ${serviceName}`;
  if (referenceId) {
    description += `, ref: ${referenceId}`;
  }

  const paymentPayload = [{
    title: description,
    quantity: 1,
    price: Number(paymentValue) * 100,
    currency,
    parameters: {
      serviceName
    }
  }]  

  async function createTransactionBackend() {
    const resp = await axios.post(`${backEndBaseUrl}/banks/paysera/transaction/create`, paymentPayload, { headers });
    const payseraTransactionKey = resp.data.payseraTransactionKey;
    setExternalUrl(`https://www.paysera.com/frontend/en/wallet/confirm/${payseraTransactionKey}`);
  }

  // ${backEndBaseUrl}/demoOnly/fromEmail/paysera/transaction/create/:description/:serviceName/:paymentValue/:currency

  return (
    <>
      {externalUrl 
        ? 
          <RedirectComponent url={externalUrl} />
        :
          <Box
            as='form'
            onSubmit={e => e.preventDefault()}
            py={3}>
            <Flex mx={-2} mb={3} flexDirection="column">
              <Box px={2} mb={3}>
                <ChooseValueInputRow 
                  name="Shkruani shumën që doni ta paguani: "
                  value={paymentValue}
                  setValue={setPaymentValue}
                />       
              </Box>
              <Box px={2}>
                <Button
                  color="white"
                  bg="black"
                  variant="outline"
                  sx={{
                      'cursor': 'pointer', 
                      ':hover': { backgroundColor: 'gray', } 
                    }}
                  onClick={() => createTransactionBackend()}  
                >
                  {`Paguaj me Paysera - ${paymentValue} ${currency}`}
                </Button>          
              </Box>    
            </Flex>    
          </Box>  
      }      
    </>
  )
}

export const ViewAllInvoicesForService: FC<{ serviceName: string}> = ({ serviceName }) => { 
  const invoiceUrl = `/fakturat/${serviceName}`;
  return (
    <Link href={invoiceUrl} variant='nav'>Shiko te gjitha fakturat</Link>
  )
};

const PdfStyledModal = Modal.styled`
  width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
`
// type PdfViewModalProps = {
//   filename: string,
//   isOpen: boolean,
//   header: string,
//   iframeTitle: string,
//   toggleModal: (val: boolean) => void
// }

export const LastInvoiceForServiceModal = ({
  serviceName,
  toggleModal,
  isOpen,
  filename
}) => {
  return (
    <PdfViewModal 
      filename={filename} 
      isOpen={isOpen}
      toggleModal={toggleModal}
      iframeTitle='Last Invoice'
      header='Faktura e fundit'
    /> 
  )
}

export const PdfViewModal = ({
  filename, 
  isOpen,
  header,
  iframeTitle,
  toggleModal
}) => {
  return (
    <PdfStyledModal
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
    >
      <Flex flexDirection='column'>
        <h3 style={{marginLeft: '25px'}}>{header}</h3>
        <iframe title={iframeTitle} src={`${backEndBaseUrl}/pdf/browser/${filename}`} style={{width: '700px', height: '900px'}} frameBorder="0"></iframe>
      </Flex>
    </PdfStyledModal>  
  )
}

const ValaDashboard = ({ allData }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const invoiceData = allData?.invoiceData;
  const lastInvoiceData = allData?.lastInvoiceReceipt;
 
  function toggleModal(e) {
    setIsOpen(!isOpen)
  }

  const invoicePeriodStartDate = new Date(invoiceData?.invoicePeriodStartDate);
  const invoicePeriodEndDate = new Date(invoiceData?.invoicePeriodEndDate);    

  return (
    <TabPanel >

      <UtilityHeading title='Gjendja - Vala Postpaid' />

      <ValaDisplayTable 
        currency={Currencies.EUR}
        totalOwed={invoiceData?.totalOwed}
        thisMonthsExpenditure={invoiceData?.thisMonthsExpenditure}
        invoicePeriodStartDate={invoicePeriodStartDate}
        invoicePeriodEndDate={invoicePeriodEndDate}
        invoicePaymentDeadline={invoiceData?.invoicePaymentDeadline }
        invoiceReceivedDate={invoiceData?.createdAt}
        lastPayment={invoiceData?.lastPayment}
        openValaInvoiceModal={setIsOpen}
      />

      <PaymentComponent 
        serviceName='valapostpaid' 
        referenceId={allData?.customerId} 
        initialValue={allData?.invoiceData.totalOwed}
      />

      <LastInvoiceForServiceModal 
        serviceName='valapostpaid' 
        toggleModal={toggleModal}
        isOpen={isOpen}
        filename={lastInvoiceData?.savedFilename}
      />

    </TabPanel>      
  )

}

const TelkosDashboard = ({ allData }) => {
  const invoiceData = allData?.invoiceData;
  const tableData = [
    {
      name: 'Borxhi total',
      value: `${invoiceData?.totalOwed} ${CURRENCY}`
    },
    {
      name: 'Faktura e fundit',
      value: `${invoiceData?.thisMonthsExpenditure} ${CURRENCY}`
    }    
  ]; 

  return(
    <TabPanel>   
      <UtilityHeading title='Gjendja - Telkos' />
      <BasicTable tableData={tableData} />
      <ViewAllInvoicesForService serviceName='telkosprime' />

    </TabPanel>  
  )
}

const utilityMappings = {
  'valapostpaid': {
    displayName: 'Vala Postpaid',
    component: ValaDashboard
  },
  'telkosprime': {
    displayName: 'Telkos Prime',
    component: TelkosDashboard
  }  
};

function fetchUtilities() {
  headers['Authorization'] = `Bearer ${localStorage.getItem('authToken').replaceAll('"','')}`;
  const resp = axios.get(`${backEndBaseUrl}/utilities/list`, { headers });
  return resp;
}

const tabStyle = {
  width:"200px",
  color: "white",
  backgroundColor: "black",
  padding: "10px",
  borderRight: "5px solid black",  
};

const tabStyleSelected = {
  width:"200px",
  color: "white",
  backgroundColor: "black",
  padding: "10px",
  borderRight: "5px solid yellow",
};

export const Dashboard = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [current, send] = useMachine(mainMachine);
  const isMobile = useCheckMobileScreen();

  const { data: utilitiesData, status } = useQuery('utilities', fetchUtilities);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  let utilities;
  let utilityComponents;
  if (utilitiesData){
    utilities = utilitiesData && utilitiesData.data.map(row => row.serviceName);
    utilityComponents = utilities.map(key => {
      return utilityMappings[key].component
    })
  }

  return (
    <Flex flexDirection={'column'} width='80%'>
      
      {status === 'loading' && 
        <InfinitySpin 
          width='200'
          color="#000"
        />
      }

      {utilities && (utilities.length === 0 || current.matches('vala.postpaid.3'))
          ?
          <>
            {utilities.length === 0 &&
              <p>Për momentin nuk e keni të regjistruar asnjë lloj të fakturës</p>
            }
            
            <MainOnboarding current={current} send={send} utilitiesData={utilitiesData} />
          </>
          :
          <Flex flexDirection='column'>
            <Tabs index={tabIndex} onChange={handleTabsChange} orientation={isMobile ? TabsOrientation.Horizontal : TabsOrientation.Vertical} style={{ marginTop: '10px'}}>
                <TabList style={!isMobile ? { marginRight: '25px' } : { marginBottom: '20px' } }>
                  {utilities && utilities.map((utility, index) => {
                    if (tabIndex === index) {
                          return <Tab key={index} style={tabStyleSelected}>{utilityMappings[utility].displayName}</Tab>
                        } else {
                          return <Tab key={index} style={tabStyle}>{utilityMappings[utility].displayName}</Tab>
                        }
                          
                  })}

                  <Box sx={{ width: '100%', backgroundColor: 'black'}}>
                    &nbsp;
                  </Box>
                  <Tab key="staticKey" style={tabStyle}>Shto fakturë të re</Tab>
                  <Box sx={{ width: '100%', backgroundColor: 'black'}}>
                    &nbsp;
                  </Box>

                </TabList>

                <TabPanels>
                  {utilityComponents && utilityComponents.map((Component, index) => {
                      return <Component key={index} allData={utilitiesData.data.filter(row => row.serviceName === utilities[index]).map(row => row).find(row => row)} />
                    })}
                    
                    <TabPanel>
                      <MainOnboarding current={current} send={send} utilitiesData={utilitiesData} />  
                    </TabPanel>

                </TabPanels>

            </Tabs>
        </Flex>
        }
    </Flex>
  )
}