// export const frontEndBaseUrl = 'https://2567-87-238-213-9.eu.ngrok.io';

export let frontEndBaseUrl = '';
export let backEndBaseUrl = '';
export let bicDigitalEmailDomain = '';

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  frontEndBaseUrl = 'https://2567-87-238-213-9.eu.ngrok.io';
  backEndBaseUrl = 'https://5640-87-238-213-9.eu.ngrok.io';
  bicDigitalEmailDomain = 'digital.bic-ks.com';
} 
else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  frontEndBaseUrl = 'https://payments.bic-ks.com';
  backEndBaseUrl = 'https://bic-payments-api.bic-ks.com';
  bicDigitalEmailDomain = 'digital.bic-ks.com';
}