import React from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Link
} from 'rebass';
import { Currencies, getAlbanianDate } from '../../common';

type BasicTableRowType = {
  name: string,
  value: string;
}

export const BasicTable = ({ tableData }: { tableData: BasicTableRowType[]} ) => {
  return (
    <Table 
      striped 
      bordered 
      responsive 
      style={{ 
        minWidth: '400px',
        marginTop: '20px'
      }}
    >
      <tbody>
        {tableData.map((row, index) => {
          return (
            <tr key={index}>
              <td>{row.name}</td>          
              <td>{row.value}</td> 
            </tr>           
          )
        })}                         
      </tbody>
    </Table>
  )
}

type ValaInvoiceDetails = {
  currency: Currencies,
  totalOwed?: Number,
  invoicePaymentDeadline?: Date,
  lastPayment?: Number,
  invoicePeriodStartDate?: Date,
  invoicePeriodEndDate?: Date,
  invoiceReceivedDate?: Date,
  thisMonthsExpenditure?: Number,
  openValaInvoiceModal?: (isOpen: boolean) => void;
}

export const ValaDisplayTable = ({
  currency = Currencies.EUR,
  totalOwed,
  invoicePaymentDeadline,
  lastPayment,
  invoicePeriodStartDate,
  invoicePeriodEndDate,
  invoiceReceivedDate,
  thisMonthsExpenditure,
  openValaInvoiceModal
}: ValaInvoiceDetails) => {
  return (
    <Table 
      striped 
      bordered 
      responsive 
      style={{ 
        marginTop: '20px'
      }}
    >
      <tbody>
        <tr>
          <td>Borxhi total</td>          
          <td>
            {totalOwed && 
              <>
                {totalOwed} {currency}
              </>
            }
          </td>
        </tr>
        <tr>
          <td>Faktura e fundit</td>
          <td>
            {thisMonthsExpenditure ?
              <>
                {thisMonthsExpenditure} {currency} - <Link href="#" onClick={() => openValaInvoiceModal(true)}>Shiko fakturën</Link>
                <br />
                <small>Pranuar me: {getAlbanianDate(invoiceReceivedDate)}</small>
              </>
              : 
              <span>-</span>             
            }
          </td>
        </tr>
        {invoicePeriodStartDate && invoicePeriodEndDate && 
         <tr>
          <td>Periudha e fakturimit</td>
          <td><>{getAlbanianDate(invoicePeriodStartDate)} - {getAlbanianDate(invoicePeriodEndDate)}</></td>
         </tr>       
        }

        {invoicePaymentDeadline &&
         <tr>
          <td>Afati për pagesën e faturës</td>
          <td>{getAlbanianDate(invoicePaymentDeadline)}</td>
         </tr>       
        }         

        <tr>
          <td>Pagesa e fundit nga ana juaj</td>
          <td>
            {lastPayment ? 
              <>{lastPayment} {currency}</>
              : 
              <span>-</span> 
            }
          </td>
        </tr>
        <tr>
          <td>Minimumi per pagese</td>
          <td>
            {totalOwed ? 
              <>{(Number(totalOwed) * 0.7).toFixed(2)}</>
              : 
              <span>-</span> 
            }            
          </td>
        </tr>                            
      </tbody>
    </Table>
  );
}
