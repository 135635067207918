import React from "react";
import {
  useSearchParams
} from "react-router-dom";
import {
  Flex,
} from 'rebass';
import { RedirectComponent } from "../utils/Redirect";
import axios from 'axios';
import { headers } from "../../common";
import { frontEndBaseUrl, backEndBaseUrl } from "../utils/config";


export const PayseraRedirectPage = () => {
  const [searchParams] = useSearchParams();
  const [seconds, setSeconds] = React.useState<number>(5);
  const bicTransactionId = searchParams.get('bicTransactionId');
  const status = searchParams.get('status');

  React.useEffect(() => {
    if (bicTransactionId && status === 'success') {
        // This is redirect_uri from Paysera.Call the Paysera ../:transaction_key/confirm API
        async function confirmPayseraTransaction (bicTransactionId) {
            const payload = {
              bicTransactionId
            }
            try {
                await axios.put(`${backEndBaseUrl}/banks/paysera/transaction/confirm`, payload, { headers });
                console.log(`Confirming paysera transaction: ${bicTransactionId}`);
              } catch(err) {
                console.log('Catching error transaction/confirm:' + err);
            }
          };

          confirmPayseraTransaction(searchParams.get('bicTransactionId'));  
    }

    const interval = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearInterval(interval);

  }, [searchParams, bicTransactionId, status, seconds]);

  return (
    <Flex flexDirection={'column'} justifyContent={'center'}>
      <h3>Pagesa juaj u konfirmua...</h3>
      <Flex justifyContent={'center'}>
        {seconds === 0 ? 
          <RedirectComponent url={`${frontEndBaseUrl}/redirect/dashboard`} />
          :
          <h3>{seconds}</h3>
        }
      </Flex>
    </Flex>
  )
}