import * as React from 'react';
import { useLocalStorage } from "./useLocalStorage";
import { bicDigitalEmailDomain } from "./components/utils/config";
import jwt from 'jwt-decode';

export const AuthContext = React.createContext('Default Value');

function checkIfAuthTokenIsNewerThan24Hours(tokenExpiryTimeStamp) {
    const then = new Date(tokenExpiryTimeStamp * 1000);
    const now = new Date();
    
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    
    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    
    console.log(`HoursBetween: ${hoursBetweenDates}`);
    
    if (hoursBetweenDates < 24) {
      return true;
    } else {
      return false;
    }

}

function useAuthUtils() {
    // const [authed, setAuthed] = React.useState(false);
    const [authed, setAuthTokenLocalStorage] = useLocalStorage('authToken', '');
    let jwtObject = {};
    try {
        jwtObject = jwt(authed);
    } catch(err) {
        console.log('No auth token, continue');
    }

    const tokenIsValid = checkIfAuthTokenIsNewerThan24Hours(jwtObject.exp);

    let isValidAuthentication = true;
    if(!tokenIsValid) {
        isValidAuthentication = false;
    }

    // console.log(`Autheddddddddd: ${authed}`);
    // console.log(`JWTTTTTTT: ${JSON.stringify(jwtObject.exp)}`);

    const [displayName, setDisplayName] = React.useState('');
    const [, setUserIdLocalStorage] = useLocalStorage('userId', '');
    const [, setBicDigitalUsernameStorage] = useLocalStorage('bicDigitalUsername', '');




    return {
        isValidAuthentication,
        authed, 
        displayName,
        login(jwtToken, userId, displayName, bicDigitalUsername) {
            return new Promise((res) => {
                setAuthTokenLocalStorage(jwtToken);
                setUserIdLocalStorage(userId);
                setDisplayName(displayName);
                setBicDigitalUsernameStorage(`${bicDigitalUsername}@${bicDigitalEmailDomain}`);
                // setAuthed(true);
                res();
            })
            
        },
        logout() {
            return new Promise((res) => {
                setAuthTokenLocalStorage('');
                setDisplayName('');
                // setAuthed(false);
                res();
            })
        }
    }
}

export function AuthProvider({ children }) {
    const auth = useAuthUtils();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function useAuth() {
    const value = React.useContext(AuthContext);
    return value;
}