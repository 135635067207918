import React from "react";
import { useParams } from 'react-router-dom';
import { frontEndBaseUrl } from "./config";

export const RedirectComponent = ({ url }) => {
  React.useEffect(() => {
    window.location.replace(url);
  }, [url])
  return <h3>Redirecting...</h3>  
}

export const RedirectPage = () => {
  const params = useParams();

  React.useEffect(() => {
    window.location.replace(`${frontEndBaseUrl}/${params.page}`);
  }, [params])
  return <h3>Redirecting...{`${frontEndBaseUrl}/${params.page}`} </h3>  
}