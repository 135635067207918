import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Flex,
} from 'rebass';
import { AuthContext } from './useAuth';
import { BICLink } from './App';
import { slide as Menu } from 'react-burger-menu';

const PrivateMobileMenu = () => {
    const { logout } = React.useContext(AuthContext);;
    const navigate = useNavigate();
  
    const handleLogout = () => {
      logout();
      navigate('/');
    }; 
    return (
        <Menu right>
        <a className="menu-item" href="/">
            Home
        </a>
        <a className="menu-item" href="/settings">
            Settings
        </a>
        <a className="menu-item" href="/dashboard">
            Dashboard
        </a>

        <button 
            onClick={handleLogout}
            className='top-menu-button'
            style={{
                fontSize: '18px',
                marginLeft: '0px'
            }}
            >
            Logout
            </button>
        </Menu>
)};

const PublicMobileMenu = () => {
    return (
        <Menu right>
            <a className="menu-item" href="/">
                Home
            </a>
            <a className="menu-item" href="/login">
                Login
            </a>
        </Menu>
)};

const PublicMenu = () => {
    return (
      <>
        <BICLink 
          url={'/'}
          text={'Home'}
        />
  
        <BICLink 
          url={'/login'}
          text={'Login'}
        />

        <div></div>
      </>
    )
  }
  const PrivateMenu = () => {
    const { logout } = React.useContext(AuthContext);;
    const navigate = useNavigate();
  
    const handleLogout = () => {
      logout();
      navigate('/');
    }
    return (
      <>
        <BICLink 
          url={'/'}
          text={'Home'}
        />
        <BICLink 
          url={'/settings'}
          text={'Settings'}
        />
        <BICLink 
          url={'/dashboard'}
          text={'Dashboard'}
        />       
        <button 
          onClick={handleLogout}
          className='top-menu-button'
        >
          Logout
        </button>
        
        <div></div>
      </>
    )
  }
export const MainMenu = ({ isMobile }) => {
  const { authed } = React.useContext(AuthContext);

  return (    
    <Flex mr="10px"> 
      {isMobile && authed ? (
            <PrivateMobileMenu pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        ) : isMobile ? (
            <PublicMobileMenu pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        ) : authed ? (
            <PrivateMenu />
        ) : (
            <PublicMenu />
        )      
      }
    </Flex>
  );
};