import React from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {
    Flex,
    Box,
    Text,
    Button
  } from 'rebass';
import { headers } from "./common";
import { useAuth } from './useAuth';
import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";
import { backEndBaseUrl } from "./components/utils/config";


const FormElement = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const [loginStatus, setLoginStatus] = React.useState('');
    
    const attemptLogin = async (values) => {
        const payload = {
            email: values.username,
            password: values.password
        }

        let response;
        let jwtToken;
        let userId;
        try {
            response = await axios.post(`${backEndBaseUrl}/user/login`, payload, { headers });
            // Save jwtToken and userId in localStorage
            jwtToken = response.data.token;
            userId = response.data.userId;
            const bicDigitalUsername = response.data.bicDigitalUsername;

            // set authed to true and redirect
            login(jwtToken, userId, bicDigitalUsername, bicDigitalUsername).then(() => {
                navigate(state?.path || '/dashboard');
            })

        } catch(error) {
            setLoginStatus(error.message);
        }
    }
    

    return(

        <Formik
        initialValues={{
            username:'',
            password: ''
        }}
            onSubmit={(values) => {
                attemptLogin(values);
            }}
        >
            <Form>
            <Flex mx={-2} mb={3} flexDirection="row">
                <Box width={1/1} px={2}>
                    
                    <label htmlFor="username">Username: </label>
                    <Field name="username" type="text" />
                    <ErrorMessage name="username" />
                    <br />
                    
                    <label htmlFor="password">Password:&nbsp;&nbsp;</label>
                    <Field name="password" type="password" />
                    <ErrorMessage name="password" />
                    <br />

                    <Button 
                        m={10}
                        ml='auto' 
                        sx={{
                            backgroundColor:'black'                            
                        }}
                    >
                        Login
                    </Button>
                    {loginStatus && 
                        <>
                        {loginStatus}
                        </>
                    }
                    <Link
                        to="/forgotPassword"
                    >
                        <Text fontSize={1} color={'black'}>
                            Forgot password?
                        </Text>
                        
                    </Link>
                </Box>

            </Flex>
            </Form> 
        </Formik>    

    )
}

export const Login = () => {
    const { login } = useAuth();
    const { state } = useLocation();

    let params = (new URL(document.location)).searchParams;
    let jwtToken = params.get("jwt_token"); // This jwtToken is usually provided by Facebook and Google successful callbacks
    let navigate = useNavigate();

    React.useEffect(() => {
        if (jwtToken) {
            async function checkTokenValidity (token) {
                try {
                    const res = await axios.get(`${backEndBaseUrl}/auth/verifyToken/${token}`, { headers });
                    const userId = res.data.jwt.user._id;
                    const displayName = res.data.displayName;
                    const bicDigitalUsername = res.data.bicDigitalUsername;
                    console.log(`Try success: ${JSON.stringify(res)}, userId: ${userId}, jwtToken: ${jwtToken}, data: ${res.data}`);
                    // Save jwtToken and userId in localStorage
                    login(jwtToken, userId, displayName, bicDigitalUsername).then(() => {
                        navigate(state?.path || '/dashboard');
                    })
                } catch(err) {
                    console.log('Catching error:' + err);
                }
              };

            checkTokenValidity(jwtToken);  
        }
      }, [jwtToken, login, navigate, state?.path]);

    const goToFacebookLogin = () => window.location.replace(`${backEndBaseUrl}/auth/facebook`);
    const goToGoogleLogin = () => window.location.replace(`${backEndBaseUrl}/auth/google`);

    return (
        <Flex flexDirection="column">
            <Flex mt={20}>
                <FormElement />        
            </Flex>
            <Flex width='100%'> 
                <FacebookLoginButton style={{ width: '200px', height: '40px', fontSize: '15px'}} onClick={goToFacebookLogin} />
            </Flex>
            <Flex width='100%'> 
                <GoogleLoginButton style={{ width: '200px', height: '40px', fontSize: '15px'}} onClick={goToGoogleLogin} />
            </Flex>   
        </Flex> 
    )
}