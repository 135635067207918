import { useMachine } from '@xstate/react';
import { mainMachine } from '../../mainMachine';
import { OnboardingVala } from '../../ValaContainer';
import Select from 'react-select'
import {
  Flex,
} from 'rebass';
import { ValaDisplayTable } from '../display/Tables';
import { Currencies } from '../../common';
import { Tabs, TabList, Tab, TabPanels, TabPanel, TabsOrientation } from '@reach/tabs';
import { BasicTable } from '../display/Tables';

const tabStyle = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial"
};

export const MainOnboarding = ({ current, send, utilitiesData }) => {
  const utilityProviders = [
    { value: 'vala', label: 'Vala' },
  ]

  const onChangeUtility = (utilityName) => {
    if (utilityName === 'vala') {
      send("TRANSITION_TO_VALA");
    }
  } 

  return (
    <Flex flexDirection="column">
      <h3><strong>Zgjedhni llojin e fakturës për ta shtuar</strong></h3>
      <Select 
        options={utilityProviders}
        placeholder="Zgjidhe kompaninë"
        onChange={(event) => onChangeUtility(event.value)}
      />
      <hr />

      {current.matches('vala') && 
        <OnboardingVala current={current} send={send} utilitiesData={utilitiesData} />  
      }
      <hr />

    </Flex>
  )
}