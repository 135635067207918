import React, {useEffect, useState} from "react";
import { QueryClient, QueryClientProvider } from 'react-query'
import { Link, Routes, Route, Navigate } from 'react-router-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import {
  Flex,
  Box,
  Image,
  Text,} from 'rebass';
import './App.css';
import { ModalProvider } from 'styled-react-modal'
import Theme from './Theme';
import axios from 'axios';
import { AuthContext, AuthProvider } from './useAuth';
import { Login } from './Login';
import { MainMenu } from './MainMenu';
// import { MainOnboarding } from './components/onboarding/MainOnboarding'
import { Dashboard } from "./components/display/Dashboard";
import { AllInvoices } from "./components/display/AllInvoices"
import { RedirectPage } from "./components/utils/Redirect";
import { PayseraRedirectPage } from "./components/display/Paysera";

axios.defaults.headers.common['Authorization'] = !localStorage.getItem('authToken') ? null : `Bearer ${localStorage.getItem('authToken').replaceAll('"','')}`;

export const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
          setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return (width <= 768);
}


const Home = () => {
  return (
    <Flex flexDirection={'column'}>
      <h1>Welcome to BIC Payments! isMobile: </h1>
      <p>BIC Payments is a Fintech company providing automation of invoice and tax payments in Europe and Balkans.</p>
      <p>More information to come soon...</p>
    </Flex>
    
  )
};

const Settings = () => {
  return (
    <Flex flexDirection={'column'}>
      <h1>Settings</h1>
      <span>Under construction</span>
    </Flex>
  )
}

const RequireAuth = ({ children }) => {
  const { authed } = React.useContext(AuthContext);
  const location = useLocation();


  return authed ? children: <Navigate to='/login' replace state={{ path: location.pathname}} />
}

export const BICLink = ({url, text}) => {
  const location = useLocation();
  const currentUrl = location.pathname;

  return (
    <Box ml={"20px"}>
      <Link 
        to={url}
        style={{
          paddingLeft: '20px'
        }}
      >
        <Text
          color='white'
          sx={{
            textDecoration: currentUrl === url ? 'underline' : 'none',
            ':hover': {
              textDecoration: 'underline'
            }
          }}
        >
          {text}
        </Text>
      </Link>
    </Box>
  )
} 


const NavHeader = () => {
  const { displayName } = React.useContext(AuthContext);
  const isMobile = useCheckMobileScreen();

  return (
    <Flex
      width="100%"
      px={2}
      color='white'
      bg='black'
      alignItems='center'
      justifyContent='space-between'  
    >  
      <Flex flexDirection="column" alignItems="center"> 
        
        <Link to="/">
          <Image 
            mt={10}
            mb={10}
            width='150px'
            src='/BIClogo.png'
          />
        </Link>        
        {displayName && 
          <span style={{ paddingLeft: '10px', marginBottom: '20px'}}>
            <strong>Mirë se vjen, {displayName}</strong>
          </span>
        }
      
      </Flex>
      
      <Flex>
        <MainMenu isMobile={isMobile} />
      </Flex>

  </Flex>
  )
}

const Footer = () => {
  return(
    <Flex justifyContent={'flex-end'} mr={20} mt={30}>    
      <Text 
          ml={25}
          mb={10}
          fontSize={'12px'}
        >
        Beta version 0.2. All rights reserved.
      </Text>
    </Flex>
  )
}

const RoutesComponent = () => {
  return(
    <Flex ml={25} width="100%">          
      <Routes>
        
        <Route path="/" element={<Home />} />      
        <Route path="/login" element={<Login />} />
        <Route path="/redirect/:page" element={<RedirectPage />} />        
        {/* <Route path="/onboarding/vala" element={<MainOnboarding />} /> */}
        
        <Route path="/dashboard" element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
          } />
        
        <Route path="/settings" element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        } />               

        {/* <Route path="/onboarding/vala" element={
          <RequireAuth>
            <MainOnboarding />
          </RequireAuth>
          } />         */}

        <Route path="/fakturat/:serviceName" element={
          <RequireAuth>
            <AllInvoices />
          </RequireAuth>
        } />

        <Route path="/paysera/transaction/confirmed" element={
          <RequireAuth>
            <PayseraRedirectPage />
          </RequireAuth>
        } />    

      </Routes>
    </Flex>
  )
}

const MainComponent = () => {
  const isMobile = useCheckMobileScreen();

  return (
    <Flex
      backgroundColor='#282c34'
      width='100%'
      height='100vh'
      alignItems='center'
      flexDirection='column'
      bg='cream'
    >
            <Flex 
              flexDirection={'column'}
              alignItems='flex-start'
              sx={{border: '1px solid #000'}} 
              width={isMobile ? '100%' : '80%'}
            >
              <NavHeader />
              
              <RoutesComponent />

              <Footer />    

            </Flex>

    </Flex>
  )
}

const App = props => {  
  const queryClient = new QueryClient()

  return (
    <Theme>
      <div className="App" id="outer-container">
        <div id="page-wrap">
          <BrowserRouter>
            <ModalProvider>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>              
                  <MainComponent />
                </QueryClientProvider>  
              </AuthProvider>
            </ModalProvider>
          </BrowserRouter>
        </div>  
      </div>
    </Theme>
  )
}

export default App;
