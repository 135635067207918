import React from "react";
import { headers } from "./common";
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
  Flex,
  Box,
  Text,
  Button
} from 'rebass';
import {
  Textarea,
} from '@rebass/forms';
import ModalImage from "react-modal-image";
import Select from 'react-select'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import axios from 'axios';
import Modal from 'styled-react-modal'
import { useLocalStorage } from "./useLocalStorage";
import { backEndBaseUrl } from "./components/utils/config";

export const StyledModal = Modal.styled`
  width: 30rem;
  height: 15rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
`


export const OnboardingVala = ({ current, send, utilitiesData }) => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [walkedPostPaidStep, setWalkedPostPaidStep] = React.useState(1);
  const incrementWalkedStep = () => setWalkedPostPaidStep(previousStep => {
    if (previousStep < 3) {
      return previousStep + 1;
    }
  })

  const activeValaServices = utilitiesData.data.map(row => row.serviceName);

  const [invoiceReceivedInEmail, setInvoiceReceivedInEmail] = React.useState(false);

  const valaServices = [];
  if(!activeValaServices.includes('valapostpaid')) {
    valaServices.push({ value: 'valaPostPaid', label: 'Vala Postpaid' })
  }
  if(!activeValaServices.includes('valaprepaid')) {
    valaServices.push({ value: 'valaprepaid', label: 'Vala Prepaid' })
  }  

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const onChangeValaService = (serviceName) => {
    if (serviceName === 'valaPostPaid') {
      send("SELECT_POSTPAID");
    } else if (serviceName === 'valaPrePaid') {
      send("SELECT_PREPAID");
    }
  }  

  return (
    <>

      <Select 
        options={valaServices}
        placeholder="Zgjidhe sh&euml;rbimin..."
        onChange={(event) => onChangeValaService(event.value)}
      />    
      <hr />

    {current?.value.vala?.postpaid &&
      <Tabs index={tabIndex} onChange={handleTabsChange}>

        <TabList>
          <Tab>Hapi 1</Tab>
          <Tab disabled={walkedPostPaidStep < 2}>Hapi 2</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ValaPostPaidStep1 
              current={current} 
              send={send} 
              incrementWalkedStep={incrementWalkedStep} 
              setTabIndex={setTabIndex}
            />
          </TabPanel>
          <TabPanel>
            <ValaPostPaidStep2 
              incrementWalkedStep={incrementWalkedStep} 
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              walkedPostPaidStep={walkedPostPaidStep}
              invoiceReceivedInEmail={invoiceReceivedInEmail}
              setInvoiceReceivedInEmail={setInvoiceReceivedInEmail}
            />
          </TabPanel>
        </TabPanels>

      </Tabs>    
      }
    </>
  
  )
}

export const ValaPostPaidStep1 = ({current, send, incrementWalkedStep, setTabIndex }) => {
  const [userIdLocalStorage,] = useLocalStorage('userId', '');

  async function setFinanceEmail(newFinanceEmail) {
    try {
      const payload = {
        newFinanceEmail
      }
      await axios.post(`${backEndBaseUrl}/user/setFinanceEmail/${userIdLocalStorage}`, payload, { headers });
    } catch(err) {
      console.log(`setFinanceEmail error: ${err}`)
    }
  }

  return (
  <Flex flexDirection="column" justifyContent="flex-start" sx={{marginTop: '15px', marginLeft: '10px'}}>
          <Flex>
            <span>1.1. Gjeni fakturen tuaj t&euml; fundit postpaid nga Vala (si n&euml; foto): </span>
            <Box sx={{ marginLeft: '10px', marginBottom: '20px', border: '1px solid black;'}}>
              <ModalImage
                hideDownload
                hideZoom
                small='/valaPostpaidInvoice_thumbnail.png'
                large='/valaPostpaidInvoice.png'
                alt="Faktura - Vala Postpaid"
              />
            </Box>
          </Flex>

          <span>1.2. Shkruajeni e-mailin tuaj n&euml; t&euml; cilin e pranoni fakturen nga Vala:</span>
            <Formik
              initialValues={{
                  financeEmailAddress:'',
                  username: ''
              }}
              validateOnBlur
              validationSchema={
                yup.object().shape({
                  financeEmailAddress: 
                    yup.string()
                      .email('*Email invalid, ju lutemi shenoni nje email valid')
                      .required('*Ju lutemi plotesoni kete fushe'),
                })
              }
                  onSubmit={(values) => {
                      setFinanceEmail(values.financeEmailAddress);
                      incrementWalkedStep();
                      setTabIndex(1);
                      send("NEXT");
                  }}
            >
                  {({ isSubmitting, dirty, isValid }) => (
                    <Form>
                      <Box>
                        <Field 
                          name="financeEmailAddress" 
                          type="text" 
                          placeholder=' - Sheno e-mailin ketu...'
                          style={{ 
                            border: '2px solid black',
                            width: '300px',
                            minHeight: '30px',
                            marginTop: '15px',
                            marginBottom: '15px'
                          }}
                        />
                        <Text style={{ color: 'red', marginBottom: '10px' }} >
                          <ErrorMessage name="financeEmailAddress"/>
                        </Text>    

                        <Button 
                          sx={{ 
                            color: 'white', 
                            backgroundColor: dirty && isValid ? 'black' : 'gray'
                          }}
                          disabled={dirty && !isValid}
                        >
                            {current?.value.vala?.postpaid === '1' ? 'Dërgo': 'Dërguar!'}
                        </Button>

                      </Box>
                    </Form>                   
                  )} 

          </Formik>  
        </Flex>
  )
}

export const ValaPostPaidStep2 = ({ walkedPostPaidStep, incrementWalkedStep, tabIndex, setTabIndex, invoiceReceivedInEmail, setInvoiceReceivedInEmail }) => {
  const [bicDigitalUsername,] = useLocalStorage('bicDigitalUsername', '');
  const [userIdLocalStorage,] = useLocalStorage('userId', '');

  React.useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (walkedPostPaidStep === 2 && tabIndex === 1) {
          const resp = await axios.get(`${backEndBaseUrl}/emailReceipts/onboarding/vala/postpaid/checkIfFirstInvoiceReceived/${userIdLocalStorage}`, { headers });
          if (resp.data.valaFirstInvoiceReceived) { 
            // continue to step 3
            setTabIndex(2);
            incrementWalkedStep();
            setInvoiceReceivedInEmail(true);
            console.log(`Email received: ${resp.data}`);
          } else {
            console.log(`Email still hasn't been received: ${resp.data}`);
          }
        }
      } catch (error) {
        console.log(`There was an error 2: ${error}`);
      }
    }

    const intervalId = setInterval(() => {
      fetchMyAPI()
    }, 1000 * 5) // in milliseconds
    return () => clearInterval(intervalId)
  }, [walkedPostPaidStep, tabIndex, incrementWalkedStep, setTabIndex, setInvoiceReceivedInEmail, userIdLocalStorage ])

  return (
  <Flex flexDirection='column' sx={{marginTop: '15px', marginLeft: '10px'}}>
    <span>2.1. Forwardoni fakturen tuaj tek emaili: <strong>{bicDigitalUsername}</strong></span>
    <br />
    <span>Pasi ta forwardoni fakturen, <b>do i dergohet një e-mail Vala Support-it</b> që nga tani faktura mujore të dërgohet edhe në platformën tonë.</span>
    <br />
    <br />
    <Text sx={{ color: 'red', textDecoration:'underline'}}>Statusi:<br/></Text> 
    {invoiceReceivedInEmail ? (
      <span style={{ marginTop: '10px'}}>Faktura juaj &euml;sht&euml; pranuar me sukses!</span>
    ) : (
      <span style={{ marginTop: '10px'}}>Faktura juaj akoma nuk &euml;sht&euml; pranuar n&euml; <strong>{bicDigitalUsername}</strong> .</span>
    )}
  </Flex>
)}

type ValaPostPaidStep3Props = {
  walkedPostPaidStep: number;
  phase?: string;
}
export const ValaPostPaidStep3 = ({ 
  walkedPostPaidStep,
  phase
}: ValaPostPaidStep3Props) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [copyText, setCopyText] = React.useState('Kopjo tekstin');
  const [financeEmailChangeRequested, setFinanceEmailChangeRequested] = React.useState(false);

  const [userIdLocalStorage,] = useLocalStorage('userId', '');
  const [bicDigitalUsername,] = useLocalStorage('bicDigitalUsername', '');

  React.useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (walkedPostPaidStep === 3) {
          const resp = await axios.get(`${backEndBaseUrl}/emailReceipts/onboarding/vala/postpaid/checkIfFinanceEmailChangeRequested/${userIdLocalStorage}`, { headers });
          if (resp.data.financeEmailChangeRequested) {
            setFinanceEmailChangeRequested(true);
            setIsOpen(true);
            console.log(`Change Finance Email request received: ${resp.data}`);
          } else {
            console.log(`Change Finance Email request still hasn't been received: ${resp.data}`);
          }
        }
      } catch (error) {
        console.log(`There was an error 3: ${error}`);
      }
    }

    const intervalId = setInterval(() => {
      fetchMyAPI()
    }, 1000 * 5) // in milliseconds
    return () => clearInterval(intervalId)
  }, [walkedPostPaidStep, setFinanceEmailChangeRequested, userIdLocalStorage] )

  return (
  <Flex flexDirection='column' sx={{ marginTop: '15px', marginLeft: '10px', borderBottom: '1px solid black', marginBottom: '10px'}} maxWidth={'600px'}>
    <ValaPostPaidStep3Modal isOpen={isOpen}/>

    {phase && phase !== 'postRegistration' && 
      <>
        <span style={{ marginTop: '10px'}}>Faktura juaj &euml;sht&euml; pranuar me sukses n&euml;: <u>{bicDigitalUsername}</u>!</span>
        <br/>
      </>
    }
    <span style={{ marginTop: '10px'}}>3.1. N&euml; m&euml;nyr&euml; q&euml; faktura juaj t&euml; procesohet automatikisht cdo muaj nga platforma jon&euml;, d&euml;rgoni email n&euml;: <u>support@vala-mobile.com</u> nga emaili juaj <u>finance@bic-ks.com</u>, me tekstin: </span>
    <Box mt='15px'>
        <Textarea
          id='comment'
          name='comment'
          value={`Pershendetje Vala Support, \n\nNga tani luteni te na dergoni fakturen postpaid te Vales ne adresen: ${bicDigitalUsername}\n\nJu faleminderit,`}
          rows={7}
          readOnly
        />
        <Button 
         onClick={() => {
          navigator.clipboard.writeText(document.getElementById("comment").textContent);
          setCopyText('Teksti u kopjua!');
         }}
          sx={{ 
            backgroundColor: 'black', 
            marginTop: '15px',
            marginBottom: '15px',
            '&:hover, &:focus': {
              background: '#707070',
              borderColor: '#707070',
              cursor: 'pointer'
            }
          }}
          >
            {copyText}
        </Button>
      </Box>
    
    <span style={{ marginTop: '10px'}}>3.2. Vendosni n&euml; CC e-mailin: <u>{bicDigitalUsername}</u></span>
    
    <Flex>
      <span style={{ marginTop: '10px'}}>3.3. Shembull se si duhet t&euml; duket e-maili:</span>
      <Box sx={{ marginLeft: '10px', marginBottom: '20px', marginTop: '15px', border: '1px solid black;'}}>
        <ModalImage
          hideDownload
          hideZoom
          small='/valaEmail_thumbnail.png'
          large='/valaEmail.png'
          alt="Faktura - Vala Postpaid"
        />
      </Box>
    </Flex>

    <span>N&euml; k&euml;t&euml; menyr&euml;, faktura juaj cdo muaj do t&euml; procesohet tek ne, dhe pastaj do ju dergohet automatikisht juve.</span>

    <Text sx={{ color: 'red', textDecoration:'underline', marginTop: '20px'}}>Statusi:<br/></Text> 
    {financeEmailChangeRequested ? (
      <span style={{ marginTop: '10px'}}>K&euml;rkesa juaj &euml;sht&euml; pranuar me sukses!</span>
    ) : (
      <span style={{ marginTop: '10px'}}>K&euml;rkesa juaj akoma nuk &euml;sht&euml; pranuar n&euml; <strong>{bicDigitalUsername}</strong> .</span>
    )}

  </Flex>
)};

const ValaPostPaidStep3Modal = ({ isOpen }) => {
  const [bicDigitalUsername,] = useLocalStorage('bicDigitalUsername', '');
  let navigate = useNavigate();
  const exitValaPostPaid = () => navigate('/ValaPostPaidStep3Successful');


  return (
  <div>
    <StyledModal
      isOpen={isOpen}
    >
      <Flex flexDirection='column'>  
        <br />
        <Box style={{ padding: '10px'}}>
          <span>Emaili juaj &euml;sht&euml; derguar, dhe procesi i regjistrimit tek ne ka perfunduar. 
            <br/><br/>
            Tani duhet pritur konfirmimin nga Vala p&euml;r nd&euml;rrimin e e-mailit n&euml; <u>{bicDigitalUsername}</u>
          </span>
          <br /><br/><br/>
          <Button 
            sx={{ 
              color: 'white', 
              backgroundColor: 'black',
              '&:hover, &:focus': {
                background: '#707070',
                borderColor: '#707070',
                cursor: 'pointer'
              }
            }}
            onClick={exitValaPostPaid}>
              Vazhdoni n&euml; aplikacion
          </Button>
        </Box>
      </Flex>
    </StyledModal>
</div>
)};