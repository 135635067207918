// export const baseBackendUrl = 'https://a8pyi0eg9c.execute-api.eu-central-1.amazonaws.com/LiveStage/testi';
// export const baseBackendUrl = 'https://5640-87-238-213-9.eu.ngrok.io';
// export const baseBackendUrl = 'http://localhost:5002';

export const headers = {
    accept: "*/*",
    "Access-Control-Allow-Origin": "*"
}

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export const CURRENCY = 'EUR';

export enum Currencies {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP'
  }

  const albanianMonthNamesArray = ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor']

  export function getAlbanianDate(dateString) {
    const date = new Date(dateString); 
     
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    
    const fullString = `${day} ${albanianMonthNamesArray[monthIndex]} ${year}`;

    return fullString
  }